import { useState } from "react";
import { styled } from "styled-components";

export function App() {
	const [page, setPage] = useState<number>(1);

	return (
		<>
			<Logo />
			<Layout>
				<Menu>
					<MenuItem onClick={() => setPage(1)}>Kdo jsme</MenuItem>
					<MenuItem onClick={() => setPage(2)}>Ceník</MenuItem>
					<MenuItem onClick={() => setPage(3)}>Vybavení</MenuItem>
					<MenuItem onClick={() => setPage(4)}>Kontakt</MenuItem>
				</Menu>
				{page === 1 &&
					<Content>
						<Header>Kdo jsme</Header>
						<Text>já a Martin</Text>
					</Content>
				}
				{page === 2 &&
					<Content>
						<Header>Ceník</Header>
						<Text>Tak třeba 10k ta vystoupení libovolného druhu. 5K příplatek když budu línej.</Text>
					</Content>
				}
				{page === 3 &&
					<Content>
						<Header>Vybavení</Header>
						<Text>
							1x Yeelight žárovka<br />
							1x Slon<br />
							1x Free verze ChamSysu<br />
							Nějaký repráky pobraný kapele která to stejně fláká...<br />
							Random dínský RGB LED pásky ovládaný ESPčkem<br />
							Martinovy zbytkový světla
						</Text>
					</Content>
				}
				{page === 4 &&
					<Content>
						<Header>Kontakt</Header>
						<Text>Sejdem se o půlnoci na Václaváku.. za KFC</Text>
					</Content>
				}
			</Layout>
		</>
	);
}

const Logo = styled.div`
	background-image: url('./media/logo.png');
	width: 716px;
    height: 122px;
	margin: 32px;
`;

const Layout = styled.div`
	display: grid;
	grid-template-columns: 0.25fr 0.75fr;
`;

const Content = styled.div`
	padding-right: 96px;
`;

const Menu = styled.ul`
	list-style-type: none;
	color: #1684ac;
	margin: 64px 32px;
`;

const MenuItem = styled.li`
	margin: 16px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	margin-left: 0px;

	&:hover {
		color: white;
		text-shadow: 0px 0px 20px #1684ac;
	}

	&:before {
		content: '> ';
	}

	&:after {
		content: ' <';
	}

	&:before, &:after {
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
	}

	&:hover:before, &:hover:after {
		opacity: 1;
	}
`;

const Header = styled.h1`
	color: #1684ac;
`;

const Text = styled.p`

`;